import * as React from 'react';
import {Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Grid, Box, Typography, Container, ThemeProvider, createTheme, makeStyles} from '@material-ui/core'
import { Lock } from "@material-ui/icons";
import { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        CSC
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
      border: "1px solid grey",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      backgroundColor: "white",
  },
}));

const theme = createTheme();

export default function SignUp() {
  const classes = useStyles();
  const initialValues2 = { username: "", email: "", password: "" };
  const [formValues2, setFormValues2] = useState(initialValues2);
  const [formErrors2, setFormErrors2] = useState({});
  const [loading, setLoading] = useState(true)
  const { signup, updateProfile, updateUserData } = useAuth()
  const history = useHistory();

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    setFormValues2({ ...formValues2, [name]: value });
  };

  const validate2 = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.username) {
      errors.username = "Name is required!";
    }
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }
    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password.length < 4) {
      errors.password = "Password must be more than 4 characters";
    } else if (values.password.length > 10) {
      errors.password = "Password cannot exceed more than 10 characters";
    }
    if (!values.phone) {
      errors.phone = "Phone is required!";
    }
    if (!values.barcode) {
      errors.barcode = "Bar association is required!";
    }
    if (!values.lawfirmname) {
      errors.lawfirmname = "Law Firm is required!";
    }
    if (!values.fieldoflaw) {
      errors.fieldoflaw = "Field of Law is required!";
    }
    if (!values.city) {
      errors.city = "City is required!";
    }
    if (!values.province) {
      errors.province = "Province is required!";
    }
    return errors;
  };

  async function handleSubmit2(e) {
    e.preventDefault();
    const errorList = validate2(formValues2);
    if (Object.keys(errorList).length === 0) {
      setFormErrors2({});
      setLoading(true);
      await signup(formValues2.email, formValues2.password, formValues2.username);
      await updateProfile(formValues2);
      formValues2.password = "";
      formValues2.isLawyer = true;
      formValues2.fee = null;
      formValues2.offersServices = false;
      formValues2.description = "This lawyer has not set up their consultation call service as of yet. Please contact them for more details.";
      await updateUserData(formValues2);
      history.push("/");
    } else {
      setFormErrors2(errorList);
    }
    setLoading(false);
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '90vh' }}
    >
  
    <Grid item xs={12} className = {classes.container}>
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" style = {{backgroundColor: "white"}}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <Lock/>
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign Up As A Lawyer
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit2} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  name="email" value={formValues2.email} onChange={handleChange2} error = {Boolean(formErrors2?.email)} helperText = {formErrors2?.email} fullWidth label='Email' placeholder="Enter your email"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  autoComplete="given-name"
                  required
                  fullWidth
                  id="username"
                  autoFocus
                  TextField name="username" value={formValues2.username} onChange={handleChange2} error = {Boolean(formErrors2?.username)} helperText = {formErrors2?.username} label='Full Name' placeholder="Enter your name"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="phone" value={formValues2.phone} onChange={handleChange2} error = {Boolean(formErrors2?.phone)} helperText = {formErrors2?.phone} fullWidth label='Phone Number' placeholder="Enter your phone number"/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password" type="password" value={formValues2.password} onChange={handleChange2} error = {Boolean(formErrors2?.password)} helperText = {formErrors2?.password} fullWidth label='Password' placeholder="Enter your password"/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="barcode" value={formValues2.barcode} onChange={handleChange2} error = {Boolean(formErrors2?.barcode)} fullWidth helperText = {formErrors2?.barcode} label='Bar Association' placeholder="Bar Association"/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="lawfirmname" value={formValues2.lawfirmname} onChange={handleChange2} error = {Boolean(formErrors2?.lawfirmname)} fullWidth helperText = {formErrors2?.lawfirmname} label='Law Firm Name' placeholder="Law Firm Name*"/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="fieldoflaw" value={formValues2.fieldoflaw} onChange={handleChange2} error = {Boolean(formErrors2?.fieldoflaw)} fullWidth helperText = {formErrors2?.fieldoflaw} label='Field of Law' placeholder="Field of Law*"/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="city" value={formValues2.city} onChange={handleChange2} error = {Boolean(formErrors2?.city)} fullWidth helperText = {formErrors2?.city} label='City' placeholder="Enter your city"/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="province" value={formValues2.province} onChange={handleChange2} error = {Boolean(formErrors2?.province)} fullWidth helperText = {formErrors2?.province} label='Province' placeholder="Province" />
              </Grid>
              
              
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={{ marginTop: 20, marginBottom: 5 }}
              color = 'primary'
              
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
    </Grid>`
    </Grid>
  );
}