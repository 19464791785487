import React from 'react'
import { Button, Grid } from '@material-ui/core'
import { Link } from 'react-router-dom';
const Signup = () => {
    return (
      
  <Grid
    container
    spacing={2}
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ minHeight: '50vh' }}
  >
  
    <Grid item xs={6}>
    <Link to="/register/client" variant="body2">
      <Button
        style={{ width: 450, height: 80, fontSize: "30px" }}
        size = "large"
        variant = "contained"
        color = "primary"
        >
        I am a client
      </Button>
      </Link>
    </Grid>

    <Grid item xs={6}>
    <Link to="/register/lawyer" variant="body2">
       <Button
          style={{ width: 450, height: 80, fontSize: "30px" }}
          size = "large"
          variant = "contained"
          color = "primary"
          >
          I am a lawyer
        </Button>
      </Link>
    </Grid>
    <Grid item>
          <Link to="/login" variant="body2">
            Already have an account? Sign in
          </Link>
        </Grid>
    </Grid>
        
    )
}

const itemData = [
    {
      img: 'https://nowtoronto.com/wp-content/uploads/2020/05/lawyer-client-980x618.jpg',
      title: 'Client',
      text: 'I AM A CLIENT',
      linkTo: '/register/client'
    },
    {
      img: 'https://www.rbhf.ca/wp-content/uploads/2021/05/real-estate-lawyer.jpg',
      title: 'Lawyer',
      text: 'I AM A LAWYER',
      linkTo: '/register/lawyer'
    },
  ];
  
export default Signup;